<template>
    <div style="width: 100%;height: 100%">
        <Echart width="100%" height="100%" :options="options"></Echart>
    </div>
</template>

<script>
import Echart from "@/common/echart";
export default {
	name: "pi-chart",
    components:{Echart},
	data() {
		return {
			options: {
				color:['rgba(0,255,255,0.8)'],
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow'
					}
				},
				grid: {
					left: '3%',
					right: '4%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: [
					{
						type: 'category',
						data: ['北美', '欧洲', '东南亚', '日韩'],
						axisTick: {
							//alignWithLabel: true
						}
					}
				],
				yAxis: [
					{
						type: 'value'
					}
				],
				series: [
					{
						name: '商家',
						type: 'bar',
						barWidth: '40%',
						data: [1056, 520, 200, 634, 390, 330, 220]
					}
				]
			}
		}
	}
}
</script>

<style scoped>

</style>
