<template>
    <dv-active-ring-chart :config="config" style="width:100%;height:100%" />
</template>

<script>
export default {
	name: "ringChart",
    data(){
		return {
			config:{
				radius: '70%',
				activeRadius: '75%',
				data: [
					{
						name: '亚马逊',
						value: 27659
					},
					{
						name: 'eBay',
						value: 6342
					},
					{
						name: 'Lazada',
						value: 5271
					},
					{
						name: 'TikTOK',
						value: 1426
					},
					{
						name: 'OZON',
						value: 715
					}
				],
				digitalFlopStyle: {
					fontSize: 14
				},
				lineWidth:40,
				showOriginValue: true
            }
        }
    }
}
</script>

<style scoped>

</style>
