<template>
    <div style="width: 100%;height: 100%">
        <Echart width="100%" height="100%" :options="options"></Echart>
    </div>
</template>

<script>
import Echart from "@/common/echart";
export default {
	name: "pi-chart",
    components:{Echart},
	data() {
		return {
			options: {
				legend: {
					top: '80%'
				},
				series: [
					{
						name: 'Nightingale Chart',
						type: 'pie',
						radius: [10, 30],
						center: ['50%', '40%'],
						roseType: 'area',
						itemStyle: {
							borderRadius: 8
						},
						data: [
							{value: 780, name: '个人卖家'},
							{value: 220, name: '企业卖家'},


						]
					}
				]
			}
		}
	}
}
</script>

<style scoped>

</style>
