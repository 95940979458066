<template>
    <div style="width: 90%;height: 90%;padding: 5px;overflow: hidden;margin: 0 auto">
        <dv-scroll-ranking-board :config="config" />
    </div>
</template>

<script>
export default {
	name: "ranking-board",
    data(){
		return {
			config: {
				data: [
					{
						name: '亚马逊',
						value: 47.9
					},
					{
						name: 'eBay',
						value: 12.1
					},
					{
						name: '乐天',
						value: 5.63
					},
					{
						name: 'Shopee',
						value: 5.6
					},
					{
						name: '速卖通',
						value: 5.25
					},
					{
						name: 'Etsy',
						value: 4.47
					},
					{
						name: '沃尔玛',
						value: 4.08
					},
					{
						name: '美客多',
						value: 3.63
					},
					{
						name: 'Wildberries',
						value: 3.43
					},
                    {
						name: 'Ozon',
						value: 3.16
					}
				],
				rowNum: 9,
                unit:'亿'
			}
        }
    }
}
</script>

<style >
.rank {
    color: #00ffff !important;
}
</style>
