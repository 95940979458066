<template>
    <div style="width: 100%;height: 100%">
        <Chart :cdata="cdata"/>
    </div>
</template>

<script>
import Chart from './chart.vue'

export default {
	data() {
		return {
			cdata: {
				category: [
					"1月",
					"2月",
					"3月",
					"4月",
					"5月",
					"6月",
					"7月",
					"8月",
					"9月",
					"10月",
					"11月",
					"12月",
				],
				lineData: [
					18092,
					20728,
					24045,
					28348,
					32808,
					36097,
					59867,
					69715,
					78444,
					90415,
					156061,
					92677,


				],
				rateData: []
			}
		};
	},
	components: {
		Chart,
	},
	mounted() {
		this.setData();
	},
	methods: {
		// 根据自己的业务情况修改
		setData() {
			for (let i = 0; i < this.cdata.lineData.length; i++) {
				const last = this.cdata.lineData[i - 1] || this.cdata.lineData[0]
				console.log(this.cdata.lineData[i]);
				let rate = (this.cdata.lineData[i] - last) / last;
				this.cdata.rateData.push(rate.toFixed(2));
			}
		},
	}
};
</script>

<style lang="scss" scoped>
</style>
