<template>
    <div style="width: 100%;height: 100%">
        <Echart height="100%" :options="options"></Echart>
    </div>
</template>

<script>
import Echart from "@/common/echart";
import {nameMap, countries, shopList} from "@/views/index/data";

export default {
	name: "map",
	components: {
		Echart
	},
	data() {
		return {
			options: {
				title: {
					show:false
				},

                tooltip: {
					backgroundColor: '#fff',
					trigger: 'item', formatter: function (val) {
						try {
							const {data} = val

							const {name} = data
							if (name) {
								return `<div style="color: red;background: #fff;display: flex;align-items: center">${shopList[name].map(shop => '<img style="width: 50px" src="' + shop + '" alt="">')}</div>`
							}
							return ''
						} catch (e) {
							console.log(e);
						}
					}
				},
                visualMap: {
					type: 'continuous', show: false, min: 0,
					max: 1000000, textStyle: {
						fontSize: 14,
						color: '#fff'
					},
					realtime: false, inRange: {
						color: ['#ff3863', '#e6ac53', '#74e2ca', '#85daef', '#9feaa5', '#5475f5']
					}
				},
				series: [{
					data: countries.map(item => ({
						name: item,
						value: ''
					})),

					type: 'map',
                    name: '世界地图',
					left: 10, top: 10, right: 10, bottom: -100,
					aspectScale:1,
					boundingCoords: [
						// 定位左上角经纬度
						[-180, 90],
						// 定位右下角经纬度
						[180, -90]
					],
					mapType: 'world', roam: false, label: {
						show: true, color: '#fff', formatter: (params) => {
							const {name} = params
							if (countries.indexOf(name) > -1) {
								return params.name
							}
							return ''
						}, fontSize: 12
					},
					itemStyle: {
						areaColor: {
							type: 'radial',
							x: 0.5,
							y: 0.5,
							r: 0.5,
							colorStops: [{
								offset: 0, color: 'rgba(88,255,255,0.1)' // 0% 处的颜色
							}, {
								offset: 1, color: 'rgba(88,255,255,0.3)' // 100% 处的颜色
							}],
							global: false // 缺省为 false
						},
						borderWidth: 0.2,
						borderColor: 'rgba(0,255,255,1)',
						borderType: 'solid'
					},
					emphasis: {
						label: {
							show: true,
                            color:'#fff'
						},
						itemStyle: {
							areaColor: 'rgba(0,255,255,0.5)',
							borderColor:'#fff'
						}
					},
                    nameMap: nameMap,
				}]
			}
		}
	}
}
</script>

<style scoped>

</style>
