<template>
    <div style="width: 100%;height: 100%">
        <Echart width="100%" height="100%" :options="options"></Echart>
    </div>
</template>

<script>
import Echart from "@/common/echart";


export default {
	name: "pi-chart",
	components: {Echart},
	data() {
		return {
			options: {
				legend: {
					top: '70%',
                    show:false,
					textStyle: {
						fontsize:12
					}
				},
				series: [
					{
						name: 'Nightingale Chart',
						type: 'pie',
						radius: [10, 35],
						center: ['50%', '50%'],
						itemStyle: {
							borderRadius: 8
						},
						data: [
							{value: 270, name: '3C电子'},
							{value: 60, name: 'LED灯饰'},
							{value: 150, name: '汽配用品'},
							{value: 80, name: '宠物用品'},
							{value: 210, name: '厨房用品'},
							{value: 120, name: '运动户外'},
							{value: 80, name: '健康美容'},
							{value: 30, name: '办公用品'},


						]
					}
				]
			}
		}
	}
}
</script>

<style scoped>

</style>
