<template>
    <div style="width: 90%;height: 94%;margin: 0 auto;overflow: hidden;border-bottom: 1px solid #3490ba;">
        <dv-scroll-board :config="config" style="width:100%;height:100%" />
    </div>
</template>

<script>
export default {
	name: "data-table",
    data(){
		return {
			config:{
				header: ['国家', '站点', '卖家数'],
				data: [
					['美国', 'AMAZON', '567'],
					['日本', 'AMAZON', '334'],
					['俄罗斯', 'OZON', '223'],
					['墨西哥', 'AMZON', '111'],
					['加拿大', 'SHOPIFY', '678'],
					['巴西', 'AMAZON', '234'],
					['马来西亚', 'LAZADA', '322'],
					['印尼', 'LAZADA', '111'],
					['巴西', 'AMZON', '131'],
					['韩国', 'SHOPIFY', '242']
				],
				rowNum:9,
                fontSize:12,
				headerBGC:'rgba(0,255,255,0.1)',
				oddRowBGC:'rgba(0,255,255,0.01)',
				evenRowBGC:'rgba(0,255,255,0.01)',
            }
        }
    }
}
</script>

<style lang="less">
.dv-scroll-board .header {
    background-color: transparent;
    background-image: none;
    border-top: 1px solid #3490ba;

}
.dv-scroll-board .header .header-item {

    border-right: 1px solid #3490ba;
  font-size: 12px;
    &:first-child {
      border-left: 1px solid #3490ba;
    }
}

.dv-scroll-board .rows .row-item {

  font-size: 12px;
    border-top: 1px solid #3490ba;
  &:last-child {
    border-bottom: 1px solid #3490ba;
  }
}

.dv-scroll-board .rows .row-item .ceil {
    border-right: 1px solid #3490ba;
  &:first-child {
    border-left: 1px solid #3490ba;
  }
}
</style>
