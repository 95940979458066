<template>
    <div class="index-box">
        <div class="left">
            <div class="video" style="flex: 1">
                <Box title="主流产品分类">
                    <TopRightCmp></TopRightCmp>
                </Box>

            </div>
            <div style="flex: 2">
                <Box title="卖家数量">
                    <DataTable></DataTable>
                </Box>
            </div>
            <div style="flex: 1">
                <Box title="2023卖家分类">
                    <PiChart></PiChart>
                </Box>
            </div>
        </div>
        <div class="center">
            <div style="flex: 4">
                <Box title="全球开店" is_big="1">
                    <Map></Map>
                </Box>
            </div>
            <div style="flex: 2">
                <Box title="年度交易额趋势">
                    <BottomRightChart></BottomRightChart>
                </Box>
            </div>
        </div>
        <div class="right">
            <div style="flex: 1">
                <Box title="各平台开店数">
                    <RingChart></RingChart>
                </Box>
            </div>
            <div style="flex: 1">
                <Box title="入驻趋势">
                    <BarChart></BarChart>
                </Box>

            </div>
            <div style="flex: 2">
                <Box title="全球平台访问量排行榜">
                    <RankingBoard></RankingBoard>
                </Box>
            </div>
        </div>
    </div>
</template>

<script>
import drawMixin from "../../utils/drawMixin";
import {formatTime} from '@/utils'
import Box from '@/components/box'
import Map from "@/views/index/map";
import PiChart from "_c/chart/pi-chart";
import BarChart from "_c/chart/bar-chart";
import DataTable from "_c/data-table";
import RingChart from "_c/chart/ringChart";
import TopRightCmp from "@/views/index/LeftTopIndex";
import RankingBoard from "_c/ranking-board";
import BottomRightChart from "_c/bottomLeftChart";

export default {
	mixins: [drawMixin],

	data() {
		return {
			timing: null,
			loading: true,
			dateDay: null,
			dateYear: null,
			dateWeek: null,
			weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
			decorationColor: ['#568aea', '#000000'],

		}
	},
	components: {
		Box,
        Map,
        PiChart,
        DataTable,
        BarChart,
        RingChart,
        TopRightCmp,
        RankingBoard,
		BottomRightChart
    },
	mounted() {
		this.timeFn()
		this.cancelLoading()
	},
	beforeDestroy() {
		clearInterval(this.timing)
	},
	methods: {
		timeFn() {
			this.timing = setInterval(() => {
				this.dateDay = formatTime(new Date(), 'HH: mm: ss')
				this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
				this.dateWeek = this.weekday[new Date().getDay()]
			}, 1000)
		},
		cancelLoading() {
			setTimeout(() => {
				this.loading = false
			}, 500)
		}
	}
}
</script>

<style lang="less" scoped>
.index-box {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;

  & > div {
    flex: 1;
    padding: 4px;
    height: 100%;
    display: flex;
    flex-direction: column;

    & > div {
      overflow: hidden;
      margin: 2px 0;
    }


    &.center {
      flex: 4;
    }
  }
}
</style>
